$body-font: 'Open Sans', sans-serif;
$header-font: 'Montserrat', sans-serif;

// Color 
$med-gray: #cecece;
$light-gray: #f7f7f7;
$dark-gray: #36363c;
$dark-red: #b92525;
$dark-blue: #00274C;
$mc-blue: #238ac9;
$blue: #7699D0;
$white: #fff;
$turquoise: #004c4b;

// Color Assignments - CUSTOMIZE THESE
$brand-color: #036AC9; 
$border-color: $med-gray;
$delete-color: $dark-red;
$dark-text: $dark-gray;
$light-text: $white;
$accent-color: #3DAE84;

$small-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$medium-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);