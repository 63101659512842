#footer {
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  padding-top: 20px;
  padding-bottom: 20px;
  @include respond-below(sm) {
    padding: 20px 5px;
  }
}

.row {
  max-width: 68rem;
  margin: 0 auto;

  //   &::before {
  //     display: table;
  //     content: " ";
  //     -ms-flex-preferred-size: 0;
  //     flex-basis: 0;
  //     -ms-flex-order: 1;
  //     -webkit-box-ordinal-group: 2;
  //     order: 1;
  //   }
  //   &::after {
  //     clear: both;
  //   }
}
.content {
  display: flex;
  flex-direction: row;
  text-align: left;
  vertical-align: top;
  padding-top: 30px;
  h4 {
    color: $white;
    padding: 0;
    margin: 0;
    font-weight: lighter;
  }
  a {
    color: $white;
    line-height: 0;
  }
  p {
    vertical-align: top;
    margin: 0;
  }
}

.small-12 {
  width: 100%;
}
.medium-12 {
  width: 100%;
}
// .columns {
//   width: 100%;
//   float: left;
//   padding-right: 0.9375rem;
//   padding-left: 0.9375rem;
// }
.large-3 {
  width: 25%;
  padding-bottom: 15px;
}
.large-9 {
  width: 75%;
  padding-bottom: 15px;
  p {
    font-size: 0.95em;
  }
}
.text-center {
  text-align: center;
}
a {
  text-decoration: none;
}
.social a {
  margin-right: 5px;
  display: inline-block;
  padding: 5px;
}
