@import "../../styles/styles.scss";

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-container {
  background: #fff;
  margin: 0 40px;
  width: 100%;
  @include respond-below(sm) {
    margin: 0 30px;
  }
}
.slide-inner {
  display: flex;
  flex-direction: "row";
}
.slide-left {
  position: relative;
  width: 50%;
  margin-left: 25px;
  flex-direction: "column";
  p {
    margin: 20px;
    text-align: left;
  }
  @include respond-below(sm) {
    width: 100%;
    margin: 0;
  }
}
.slide-left-top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  span {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 20px;
    background-color: #00274c;
    color: white;
    justify-content: center;
    align-items: center;
    line-height: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
  }

  h1 {
    color: #00274c;
  }
}
.number-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  justify-content: center;
  align-items: center;
  // line-height: 50px;
  text-align: center;

  span {
    text-align: center;
    vertical-align: middle;
    color: white;
  }
}
.slide-right {
  position: relative;
  display: table;
  width: 50%;
  // height: 100%;
  vertical-align: middle;
  // flex-direction: "column";
  flex: 1;
  padding: 10px;
  align-items: center;
  justify-content: center;
  @include respond-below(sm) {
    display: none;
  }

  span {
    font-size: 120px;
    height: 100%;
    font-weight: bold;
    vertical-align: middle;
    color: blue;
  }
}
.icon-container {
  display: table-cell;
  vertical-align: middle;
}

.step1 {
  background-image: url("../../assets/how-to-slider/step1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.step2 {
  background-image: url("../../assets/how-to-slider/step2.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.step3 {
  background-image: url("../../assets/how-to-slider/step3.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
