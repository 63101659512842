.spinner-container {
    z-index: 100;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5));
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes loadingspin {
    100% {
            transform: rotate(360deg)
    }
}

.spinner {
    pointer-events: none;
    width: 4em;
    height: 4em;
    border: 0.4em solid transparent;
    border-color: #eee;
    border-top-color: #251B50;
    border-radius: 50%;
    animation: loadingspin 1s linear infinite;
}


