body {
  font-family: $body-font;
  font-size: 1.6rem;
  color: $dark-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
  color: $brand-color;
}

h1 {
  color: $brand-color;
}

h2 {
  color: $accent-color;
}

a,
a:visited {
  color: $brand-color;
}

p {
  font-family: $body-font;
  font-size: 1.8rem;
  line-height: 1.5;
}

h1 {
  font-size: 4rem;

  @include respond-below(sm) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 3rem;

  @include respond-below(sm) {
    font-size: 2rem;
  }
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.8rem;
}

.white-text {
  color: white;
}

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  src: url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0");
  src: url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0")
      format("embedded-opentype"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0")
      format("woff2"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0")
      format("woff"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0")
      format("truetype"),
    url("https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular")
      format("svg");
}

@font-face {
  font-family: "Univia Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Univia Pro"), local("UniviaPro"),
    url("../fonts/univia.woff2") format("woff2"),
    url("../fonts/univia.woff") format("woff");
}
