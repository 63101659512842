.app-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  #logo-1 {
    height: 100px;
  }

  #logo-2 {
    height: 75px;
  }

  #logo-3 {
    height: 150px;
  }

  img {
    margin: 15px;
  }
}

.app-intro {
  max-width: 800px;
  margin: 0 auto;

  p {
    font-size: 1.6rem;
  }

  a {
    color: $dark-blue;
    font-weight: bold;
  }
}

.app-sub-heading {
  margin-top: 25px;
  display: flex;
  justify-content: center;

  p {
    font-size: 1.4rem;
    margin: 0;
    margin-bottom: 5px;
    line-height: 30px;
  }

  #logo-2 {
    margin-left: 15px;
    margin-right: 15px;
    height: 20px;
    width: auto;
  }

  #logo-3 {
    margin-top: -10px;
    height: 50px;
    margin-left: 15px;
  }
}
