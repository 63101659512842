#order-find-form {

    .form-group-container {
        display: flex;
    }
}

#order {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}

.order-confirmation {
     text-align: center;
     max-width: 1000px;
     margin: 0 auto;
}

.order-form {
    background-color: #f1f1f1;
    padding: 25px;

    h3 {
        margin: 0;
        padding: 10px;
    }

    .group-inputs {
        display: flex;
        flex-wrap: wrap;
    }

    .order-form-label {
        margin-bottom: 5px;
    } 

    .form-group-info {
        margin: 10px;
    }

    .form-input-info {
        color: inherit;
        font-size: 1.2rem;
    }

    .hidden {
        opacity: 0;
        height: 0;
    }

    .react-datepicker {
        font-size: 1.6rem;
    }

    .react-datepicker__current-month {
        font-size: 1.6rem;
    }

    .react-datepicker__day-name {
        margin: 10px;
    }

    .react-datepicker__day {
        margin: 10px;
    }

}

.link-container {
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
    font-size: 1.4rem;
    color: $dark-blue;    
    font-weight: bold;
    font-style: italic;


}