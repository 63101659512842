#quote {
  width: 100%;

  margin: 0 auto;

  .order-link {
    background-color: $brand-color;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: bold;
    color: white;

    a {
      color: white;
      font-style: italic;
    }
  }

  // .order-button-link {
  //     margin: 0 auto;
  //     margin-bottom: 25px;
  //     background-color: $mc-blue;
  //     text-align: center;
  //     display: inline-block;
  //     text-align: center;
  //     box-shadow: $small-shadow;
  //     padding: 15px 15px 10px 15px;
  //     font-weight: bold;
  //     color: white;
  //     text-transform: uppercase;
  //     text-decoration: none;
  //     border-radius: 5px;
  // }
}
.quote-form {
  border: none;
  background-color: $white;
  padding: 25px;
  max-width: 1000px;
  margin: auto;

  p {
    max-width: 1000px;
    margin: 0px auto;
    margin-bottom: 25px;
    font-size: 1.6rem;
  }
}

.quote-form-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .form-input {
    width: 200px;

    @include respond-below(xs) {
      width: 100%;
    }
  }
}

.quote-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  width: 100%;

  @include respond-below(sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.quote-detail {
  padding-bottom: 25px;
  max-width: 1000px;
  margin: 0 auto;

  @include respond-below(sm) {
    p {
      font-size: 1.4rem;
    }
  }
}

.quote-detail-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // background-color: #f1f1f1;
}

.quote-detail-info {
  min-width: 500px;
  // background-color: #f1f1f1;
  font-size: 1.4rem;
  width: 100%;
  padding: 25px;
  margin-bottom: 10px;
  li {
    margin-bottom: 5px;
  }
}

.pickup-delivery-details {
  margin-top: 0px;
  min-width: 500px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .row {
    svg {
      margin-left: 10px;
    }
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 25px;
    grid-gap: 10px;

    @include respond-below(sm) {
      width: 100%;
    }
  }

  .full-row {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .action-row {
    width: 100%;
    text-align: center;

    button {
      margin: 15px;
      min-width: 200px;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .row-label {
    font-weight: bold;
    font-size: 1.4rem;
  }

  padding: 25px;
}

.quote-detail-actions {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  button {
    background-color: $mc-blue;
    margin: 25px;
    width: 150px;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.quote-detail-table {
  grid-template-columns: repeat(8, minmax(100px, 1fr));
}

.pricing-detail {
  border: 1px solid $dark-text;
}

.pricing-detail-table {
  grid-template-columns: repeat(5, minmax(100px, 1fr));
}

.pickup-delivery-details {
  color: $dark-text;
}

.quote-detail-vehicles {
  width: 100%;
  margin-bottom: 10px;
}

#email-row {
  display: flex;
  justify-content: center;
}

.pricing-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .option {
    background-color: #f5f5f5;
    box-shadow: $small-shadow;
    position: relative;
    padding: 5px;
    width: 22%;
    min-width: 200px;
    margin-bottom: 10px;
    text-align: center;
    border-top: 10px solid $accent-color;

    svg {
      margin-bottom: -2px;
    }

    .illustrative-icon {
      font-size: 3rem;
      margin-top: 10px;
      margin-bottom: 10px;
      fill: $accent-color;
    }

    div {
      margin-top: 10px;
      font-size: 1.4rem;
      cursor: pointer;
      font-weight: bold;
    }

    h2 {
      font-size: 3.6rem;
      margin: 0;
      color: $brand-color;
    }

    h3 {
      width: 100%;
      text-align: center;
      color: $brand-color;
      font-weight: bold;
      font-size: 1.6rem;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    p {
      font-size: 1.4rem;
      text-align: left;
    }

    .vehicle-details {
      background-color: $light-gray;
      padding: 5px;

      p {
        margin: 0;
      }
    }
  }
}
.post-hero-copy-container {
  width: 100%;

  text-align: center;
  background-color: $mc-blue;
  color: white;

  font-size: 1.5em;
  padding: 10px;

  p {
    text-align: left;
    font-weight: bold;
    font-size: 1.4rem;
    margin: 0 25px;
    padding: 0;
    a:link {
      color: $dark-gray;
    }

    a:hover {
      color: $accent-color;
    }
  }
}
.hero-container {
  width: 100%;
  position: relative;
  // left: 50%;
  // right: 50%;
  // margin-left: -50vw;
  // margin-right: -50vw;
  height: 40vh;
  overflow: hidden;
  // background-image: (url("../../assets/hero-temp.webp"));
  // background-size: cover;
  // background-position: center;
  &::before {
    content: "";
    background-image: (url("../../assets/hero.jpg"));
    background-repeat: no-repeat;
    background-position: 50% 65%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
    @include respond-below(sm) {
      background-position: 0% 75%;
    }
  }
}

.hero-inner {
  position: relative;
  padding: 0 35px;
  h2 {
    color: $dark-gray;
    margin-bottom: 1rem;
    @include respond-below(sm) {
      font-size: 2.2rem;
    }
  }
}
.subhead-container {
  // background-color: rgba(255, 255, 255, 0.75);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  p {
    // padding: auto;
    font-weight: bold;
    font-size: 1.5rem;
    color: $dark-gray;
    @include respond-below(sm) {
      font-weight: bold;
      padding: 10px;
      background-color: rgba(247, 247, 247, 0.75);
      border-radius: 5px;
    }
  }
}

.hero-left {
  width: 48%;
  //   max-width: 500px;
  @include respond-below(sm) {
    width: 100%;
  }
}
.headline-container {
  display: flex;
  width: "100%";
  background-color: $mc-blue;
  justify-content: center;
  padding: 0px 10px;
  h1 {
    color: $white;
    text-align: center;
  }
}
.swiper {
  width: 90% !important;
  margin: 50px auto !important;
  @include respond-below(sm) {
    margin: 10px 0 !important;
    width: 100% !important;
  }
}
.mySwiper {
  background-color: $white;
  margin: 20px auto;
}
.value-prop-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 50px auto;
  padding: 25px;
  width: calc(100% - 50px);
  @include respond-below(sm) {
    flex-direction: column;
    margin: 10px;
    width: calc(100% - 20px);
    padding: 0;
  }
}
.value-prop {
  //   position: relative;
  display: flex;
  flex-direction: column;
  //   max-width: 30%;
  max-width: 28%;
  span {
    font-size: 50px;

    font-weight: bold;
    color: $mc-blue;
  }
  h3 {
    margin: 0 !important;
    color: $dark-blue;
  }
  p {
    font-size: 1.4rem;
  }
  @include respond-below(sm) {
    max-width: 100%;
    // margin: 10px auto;
    padding: 10px;
    h3 {
      font-size: 3rem;
    }
  }
}
.ready-to-quote-container {
  background-image: url("../../assets/aerial-medium.jpg");
  display: flex;
  flex-direction: row;

  height: 300px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.ready-to-quote-inner {
  padding: 0 25px 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ready-to-quote-left {
  display: flex;
  width: 50%;
  justify-content: center;
  margin: 20px;
  h2 {
    color: $white;
  }
  @include respond-below(sm) {
    margin: 0;
  }
}

.ready-to-quote-right {
  display: flex;
  width: 50%;
  justify-content: center;
  button {
    height: 50px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.4rem;
    background-color: $mc-blue;
    @include respond-below(sm) {
      font-size: 1.2rem;
    }
  }
}
